import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import styles from './Invoice.module.scss';
import useWorkflowState from '../../../../Workflow/Api/useWorkflowState';
import useTypes from '../../../../Type/Api/useTypes';
import FileViewer from '../../../../../../Generic/FileViewer/FileViewer';
import AuthenticationManagerContext from '../../../../../../../@Service/Authentication/AuthenticationManagerContext';
import Tab from '../../../../../../../@Future/Component/Generic/TabBar/Tab/Tab';
import TabBar from '../../../../../../../@Future/Component/Generic/TabBar/TabBar';
import { EntityPath } from '../../../../Path/@Model/EntityPath';
import Fields from '../../../../Fields/Fields';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import ActivityTemplateEditor from '../Template/ActivityTemplateEditor';
import ProductLinesEditor from '../../../../Viewer/Content/Activity/Product/ProductEditor/ProductLinesEditor';
import { useExpansion } from '../../../../Selection/Api/useExpansion';
import useRelatedEntity from '../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { SendInvoiceButtonMenu } from './menubutton/SendInvoiceButtonMenu';
import { EInvoicingStatus } from './EInvoicing/EInvoicingStatus';
import ExpansionGroup from '../../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import Header from '../../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import uuid from 'uuid';
import ExpansionPanel from '../../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import CardInset from '../../../../../../../@Future/Component/Generic/Card/CardInset';
import Divider from '../../../../../../../@Future/Component/Generic/Divider/Divider';
import useSetting from '../../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../../Setting/SettingStore';
import { Setting } from '../../../../../../../@Api/Settings/Setting';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { InvoiceRegime } from '../../../../../Configuration/Page/Invoicing/model/InvoiceRegime';
import useFileDownloadOptions from "../Hooks/useFileDownloadOptions";

export interface InvoiceProps
{
    entity: Entity;
}

const EasySystemsService = "EasySystems";
const EasySystemsPostedInvoiceId = "PostedInvoiceId";

export const Invoice: React.FC<InvoiceProps> = observer(
    ({
        entity
    }) =>
    {
        const types = useTypes();
        const state = useWorkflowState(entity);
        const [ regime ] =
            useSetting<InvoiceRegime>(
                SettingSource.Organization,
                Setting.InvoiceRegime
            );

        const invoiceTemplate =
            useRelatedEntity(
                entity,
                types.Activity.Invoice.RelationshipDefinition.Template,
                false
            );

        const {
            isFinal,
            previewDocumentUrl,
            finalizedDocumentUrl,
            downloadOptions,
        } =
            useFileDownloadOptions(
                entity,
                `invoice`,
                invoiceTemplate,
                types.Activity.Invoice.RelationshipDefinition.DocumentAttachment,
            );

        const isEditable =
            useComputed(
                () =>
                    (regime === 'Strict' && !isFinal) ||
                    (regime === 'Relaxed' &&
                        state &&
                        state.getObjectValueByField(types.Datastore.Field.Code) === 'Concept'
                    ),
                [
                    regime,
                    state,
                    types
                ]
            );

        const [ loadingExpansion ] = useExpansion(
            entity.entityType,
            () => [
                EntityPath.fromEntity(entity)
                    .joinTo(
                        types.Activity.Invoice.RelationshipDefinition.DocumentAttachment,
                        false),
                EntityPath.fromEntity(entity)
                    .joinTo(
                        types.Activity.Invoice.RelationshipDefinition.Template,
                        false),
                EntityPath.fromEntity(entity)
                    .joinTo(
                        types.Entity.RelationshipDefinition.ExternalIds,
                        false),
            ],
            () => [
                entity
            ],
            [
                entity,
                types
            ]
        );

        const eInvoicingExternalId =
            useMemo(
            () =>
                entity.getRelatedEntitiesByDefinition(
                    false,
                    types.Entity.RelationshipDefinition.ExternalIds
                ).find(
                    externalId =>
                    {
                       return externalId.getObjectValueByField(types.ExternalId.Field.Service) === EasySystemsService
                           && externalId.getObjectValueByField(types.ExternalId.Field.Type) === EasySystemsPostedInvoiceId
                    }
                ),
                [
                    types,
                    entity
                ]
            )

        const [ tab, setTab ] = useState(0);

        useEffect(
            () =>
            {
                if (isFinal)
                {
                    setTab(3);
                }
                else if (loadingExpansion || entity.getObjectValueByField(types.Activity.Field.NumberOfProductLines) === 0)
                {
                    setTab(0);
                }
                else
                {
                    setTab(1);
                }
            },
            [
                types,
                isFinal,
                loadingExpansion,
                entity,
                setTab
            ]
        );

        const gotoPreview =
            useCallback(
                () =>
                    setTab(1),
                [
                    setTab
                ]);

        return <div
            className={styles.root}
        >
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem>
                    <TabBar
                        value={tab}
                        appendix={
                            isEditable &&
                            <SendInvoiceButtonMenu
                                entity={entity}
                            />
                        }
                    >
                        <Tab
                            value={-1}
                            onClick={setTab}
                        >
                            <LocalizedText
                                code="Generic.Details"
                                value="Details"
                            />
                        </Tab>
                        <Tab
                            value={0}
                            onClick={setTab}
                        >
                            {types.ProductLine.Type.getName(true)}
                        </Tab>
                        {
                            !isFinal && isEditable &&
                                <Tab
                                    value={1}
                                    onClick={setTab}
                                >
                                    <LocalizedText
                                        code="Generic.Example"
                                        value="Voorbeeld"
                                    />
                                </Tab>
                        }
                        {
                            !isFinal && isEditable &&
                                <Tab
                                    value={2}
                                    onClick={setTab}
                                >
                                    <LocalizedText
                                        code="Generic.Edit"
                                        value="Bewerken"
                                    />
                                </Tab>
                        }
                        {
                            isFinal &&
                                <Tab
                                    value={3}
                                    onClick={setTab}
                                >
                                    {types.Activity.Invoice.Type.getName()}
                                </Tab>
                        }
                    </TabBar>
                </ViewGroupItem>
                {
                    tab === -1 &&
                    <div>
                        <ViewGroupItem>
                            <Fields
                                entity={entity}
                            />
                        </ViewGroupItem>

                        {
                            eInvoicingExternalId &&
                            <>
                                <Divider/>
                                <CardInset
                                    top={false}
                                >
                                    <ExpansionGroup>
                                        <ExpansionPanel
                                            id={uuid()}
                                            summary={
                                                <Header
                                                    title={
                                                        <LocalizedText
                                                            code="InvoiceDetails.EInvoicingHeader"
                                                            value="E-Facturering"
                                                        />
                                                }
                                                />
                                            }
                                            expansion= {
                                                <ViewGroupItem>
                                                    <EInvoicingStatus
                                                        entity={eInvoicingExternalId}
                                                    />
                                                </ViewGroupItem>
                                            }
                                            expanded
                                        />
                                    </ExpansionGroup>
                                </CardInset>
                            </>
                        }
                    </div>
                }
                {
                    tab === 0 &&
                        <ViewGroupItem>
                            <ProductLinesEditor
                                entity={entity}
                                disabled={!isEditable}
                            />
                        </ViewGroupItem>
                }
                {
                    tab === 1 && previewDocumentUrl &&
                        <ViewGroupItem>
                            <FileViewer
                                url={previewDocumentUrl}
                                extraDownloadOptions={downloadOptions}
                                type="pdf"
                            />
                        </ViewGroupItem>
                }
                {
                    tab === 2 &&
                        <ViewGroupItem>
                            <ActivityTemplateEditor
                                entity={entity}
                                templateEntity={invoiceTemplate}
                                onPreview={gotoPreview}
                                templateType={types.Template.Document.Invoice.Type}
                                templateRelationshipDefinition={types.Activity.Invoice.RelationshipDefinition.Template}
                                templateContentField={types.Activity.Invoice.Field.Document}
                                templateFileField={types.Activity.Invoice.Field.TemplateFile}
                                templateManagerRoute="/configuration/invoicing"
                            />
                        </ViewGroupItem>
                }
                {
                    tab === 3 &&
                        <ViewGroupItem>
                            {
                                finalizedDocumentUrl &&
                                    <FileViewer
                                        url={finalizedDocumentUrl}
                                        type="pdf"
                                    />
                            }
                        </ViewGroupItem>
                }
            </ViewGroup>
        </div>;
    }
);