import React, { useCallback, useContext, useMemo, useState } from 'react';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { observer } from 'mobx-react-lite';
import useTypes from '../../../Type/Api/useTypes';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import Input from '../../../Input/Input';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import RightAlignedButtonGroup from '../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import SaveButton from '../../../../../../@Future/Component/Generic/Button/Variant/SaveButton/SaveButton';
import { loadModuleDirectly } from '../../../../../../@Util/DependencyInjection/index';
import { FeedbackStore } from '../../../../../App/Root/Environment/Organization/Feedback/FeedbackStore';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import getViewParameters from '../../../View/Api/getViewParameters';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import Selectbox from '../../../Selectbox/Selectbox';
import { EntitySelectionBuilder } from '../../../Selection/Builder/EntitySelectionBuilder';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import {default as LabelInput} from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import ComparisonPredicate from '../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../View/Model/ViewParams';
import { Comparator } from '../../../../DataObject/Model/Comparator';
import EntityValue from '../../../../../../@Api/Automation/Value/EntityValue';
import View from '../../../View/View';
import LinkRelatedEntityButton from '../../Shared/RelatedEntityView/LinkRelatedEntity/LinkRelatedEntityButton';

export interface InternalEnvironmentProps
{
    entity: Entity;
    commitContext?: CommitContext;
}
const InternalEnvironment: React.FC<InternalEnvironmentProps> =
    ({
        entity,
        commitContext
     }) =>
    {
        const types = useTypes();

        const save =
            useCallback(
                () =>
                    commitContext.commit()
                        .then(
                            () =>
                                loadModuleDirectly(FeedbackStore)
                                    .enqueueSnackbar(
                                        localizeText(
                                            'Generic.ResourceSaved',
                                            '${resource} opgeslagen',
                                            {
                                                resource: types.InternalEnvironment.Type.getName()
                                            }),
                                        {
                                            variant: 'success',
                                            autoHideDuration: 5000,
                                        })
                        ),
                [
                    types,
                    commitContext
                ]
            );
        const entityTypeStore = useContext(EntityTypeContext);

        const [ entityTypeEntity, setEntityTypeEntity ] = useState<Entity>(undefined);
        const selections =
            useMemo(
                () => [
                    new EntitySelectionBuilder(types.EntityType.Type).selection
                ],
                [
                    types
                ]);

        const entityType =
            useMemo(
                () =>
                    entityTypeEntity
                        ? entityTypeStore.getTypeByEntityId(entityTypeEntity.id)
                        : undefined,
                [
                    entityTypeStore,
                    entityTypeEntity
                ]);

        const viewParameters =
            useMemo(
                () =>
                    entityType
                        ? getViewParameters(entityType)
                        : undefined,
                [
                    entityType
                ]);

        const path =
            useMemo(
                () =>
                    EntityPath.fromEntity(entity)
                        .joinTo(
                            types.InternalEnvironment.RelationshipDefinition.Entities,
                            false),
                [
                    types,
                    entity
                ]);

        const filter =
            useMemo(
                () =>
                    entityType
                    ? ({
                        name: entityType.getName(),
                        predicate:
                            new ComparisonPredicate(
                                new ValueFromEntityComputation(
                                    viewParameters.getParameterById(ViewParams.Entity),
                                    path.reverse().field()
                                ),
                                Comparator.Equals,
                                new EntityValue(entity))
                    })
                    : undefined,
                [
                    viewParameters,
                    entityType,
                    path,
                    entity
                ]);

        const internalEnvironmentFields =
            useMemo(
                () =>
                    [
                        types.InternalEnvironment.Field.Code,
                        types.InternalEnvironment.Field.CreateSalesOpportunityForNewLeads,
                        types.InternalEnvironment.Field.Currency,
                        types.InternalEnvironment.RelationshipDefinition.Country,
                        types.InternalEnvironment.RelationshipDefinition.Language,
                        types.InternalEnvironment.RelationshipDefinition.ProductVatGroup,
                        types.InternalEnvironment.RelationshipDefinition.ShiftedVatGroup,
                        types.InternalEnvironment.RelationshipDefinition.DataPackReferences,
                        types.InternalEnvironment.RelationshipDefinition.InvoiceTemplate,
                        types.InternalEnvironment.RelationshipDefinition.EmailInvoiceTemplate,
                        types.InternalEnvironment.RelationshipDefinition.SalesRepresentative,
                        types.InternalEnvironment.RelationshipDefinition.PartnerInvoiceTemplate,
                        types.InternalEnvironment.RelationshipDefinition.PartnerEmailInvoiceTemplate,
                        types.InternalEnvironment.RelationshipDefinition.PartnerPriceList,
                    ],
                [
                    types
                ]
            );

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={0}
                                    >
                                        <ViewGroupItem>
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    <TypeAndName
                                                        entity={entity}
                                                    />
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <MenuButton
                                                        entity={entity}
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        </ViewGroupItem>
                                        {
                                            internalEnvironmentFields
                                                .map(
                                                field =>
                                                    <ViewGroupItem>
                                                        <Input
                                                            entity={entity}
                                                            field={field}
                                                            labelPosition="left"
                                                            commitContext={commitContext}
                                                            doAutocommit={false}
                                                        />
                                                    </ViewGroupItem>
                                            )
                                        }
                                        <ViewGroupItem>
                                            <RightAlignedButtonGroup>
                                                <SaveButton
                                                    fullWidth
                                                    onClick={save}
                                                    disabled={!commitContext.isDirty()}
                                                />
                                            </RightAlignedButtonGroup>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardInset>
                                    <ViewGroup
                                        orientation="vertical"
                                        spacing={15}
                                    >
                                        <ViewGroupItem>
                                            {"Related entities"}
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <LabelInput
                                                label={types.EntityType.Type.getName()}
                                                labelPosition="left"
                                            >
                                                <Selectbox
                                                    selections={selections}
                                                    value={entity}
                                                    onChange={(entity: Entity) => setEntityTypeEntity(entity)}
                                                    autoFocus
                                                    disableConstruction
                                                />
                                            </LabelInput>
                                        </ViewGroupItem>
                                        {
                                            entityType &&
                                            <ViewGroupItem>
                                                <View
                                                    key={entityType.id}
                                                    entityType={entityType}
                                                    filter={filter}
                                                    applyFilterOnAllViews
                                                    headerAppendix={
                                                        <LinkRelatedEntityButton
                                                                entity={entity}
                                                                relationshipDefinition={path.lastJoinNode.relationshipDefinition}
                                                                parent={path.lastJoinNode.isParent}
                                                                relatedEntityType={entityType}
                                                            />
                                                    }
                                                    settingStorageKey={`InternalEnvironment.EntityId.${entity.id}`}
                                                />
                                            </ViewGroupItem>
                                        }
                                    </ViewGroup>
                                </CardInset>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(InternalEnvironment);