import React, { useCallback } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import { Grid } from '@material-ui/core';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TypeAndName from '../../Shared/TypeAndName/TypeAndName';
import Fields from '../../../Fields/Fields';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import MenuButton from '../../Shared/MenuButton/MenuButton';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import useTypes from '../../../Type/Api/useTypes';
import { GlobalEnvironment } from '../../../../../../@Global/GlobalEnvironment';
import CardHeader from '../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import Link from '../../../../../../@Future/Component/Generic/Link/Link';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import { usePackSelector } from '../../../../Configuration/Page/Packs/MySharedPackManager/Api/usePackSelector';
import { SharePackEditor } from '../../../../Configuration/Page/Packs/MySharedPackManager/SharePackEditor/SharePackEditor';

export interface ResourceProps
{
    entity: Entity;
}

const Resource: React.FC<ResourceProps> =
    ({
        entity,
     }) =>
    {
        const types = useTypes();
        const token = useEntityValue<string>(entity, types.Resource.Field.Token);

        const [ isPackSelectorVisible, ownedShareAndEnvironmentPacks ] = usePackSelector(entity);

        const link =
            useComputed(
                () =>
                    `${GlobalEnvironment.BACKEND_ENDPOINT}/public/resource/${encodeURIComponent(entity.uuid)}${token ? `?token=${encodeURIComponent(token)}` : ''}`,
                [
                    entity,
                    token
                ]);

        const openLink =
            useCallback(
                () =>
                {
                    setTimeout( () =>
                        window.open(link, '_blank')
                    );
                },
                [
                    link
                ]);

        return <BaseLayout>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Card>
                                <CardInset
                                    bottom={false}
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={15}
                                    >
                                        <ViewGroupItem
                                            ratio={1}
                                        >
                                            <TypeAndName
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <MenuButton
                                                entity={entity}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </CardInset>
                                <Fields
                                    entity={entity}
                                />
                                {
                                    isPackSelectorVisible &&
                                    <CardInset>
                                        <SharePackEditor
                                            entity={entity}
                                            ownedShareAndEnvironmentPacks={ownedShareAndEnvironmentPacks}
                                        />
                                    </CardInset>
                                }
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Card
                                inset
                            >
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={5}
                                >
                                    <ViewGroupItem>
                                        <CardHeader>
                                            <LocalizedText
                                                code="Resources.SharePublic"
                                                value="Publiekelijk delen"
                                            />
                                        </CardHeader>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <LocalizedText
                                            code="Resources.ShareLinkDescription"
                                            value="De onderstaande link kan gebruikt worden om het bestand te delen met een klant."
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <Input
                                            labelPosition="left"
                                            label={
                                                <LocalizedText
                                                    code="Resources.SharableLink"
                                                    value="Deelbare link"
                                                />
                                            }
                                        >
                                            <Link
                                                onClick={openLink}
                                                highlighted
                                            >
                                                {link}
                                            </Link>
                                        </Input>
                                    </ViewGroupItem>
                                </ViewGroup>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>;
    };

export default observer(Resource);
