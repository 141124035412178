import React, { useMemo, useState } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import { CommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/CommitContext';
import useTypes from '../../../../../Type/Api/useTypes';
import { Box, Card } from '@material-ui/core';
import getSystemDefaultView from '../../../../../View/Api/getSystemDefaultView';
import getViewParameters from '../../../../../View/Api/getViewParameters';
import ComparisonPredicate from '../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import ValueFromEntityComputation from '../../../../../../../../@Api/Automation/Function/Computation/ValueFromEntityComputation';
import { ViewParams } from '../../../../../View/Model/ViewParams';
import { EntityPath } from '../../../../../Path/@Model/EntityPath';
import { Comparator } from '../../../../../../DataObject/Model/Comparator';
import EntityValue from '../../../../../../../../@Api/Automation/Value/EntityValue';
import List, { ListSelection } from '../../../../../View/List/List';
import CardInset from '../../../../../../../../@Future/Component/Generic/Card/CardInset';
import { CommitBuilder } from '../../../../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';
import { EntitySelectionBuilder } from '../../../../../Selection/Builder/EntitySelectionBuilder';
import PrimaryButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';
import { useNewCommitContext } from '../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import { constructEntityOfType } from '../../../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/constructEntityOfType';
import { ActivityEmail } from '../../../../../Constructor/Type/Activity/Email/ActivityEmail';
import { sendEmail } from '../../../../../Constructor/Type/Activity/Email/Api/sendEmail';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import { getEmailAddressesByEntity } from '../../../../../../../../@Api/Entity/Bespoke/EmailAddress/getEmailAddressesByEntity';
import { getEmailAddressesDependencyPathsByType } from '../../../../../../../../@Api/Entity/Bespoke/EmailAddress/getEmailAddressesDependencyPathsByType';
import { EntityExpansionBuilder } from '../../../../../Selection/Builder/EntityExpansionBuilder';

export interface CourseAttendanceEmailingProps
{
    course: Entity;
    commitContext?: CommitContext;
    onSend: () => void;
}

const CourseAttendanceEmailing: React.FC<CourseAttendanceEmailingProps> =
    ({
         course,
         onSend,
     }) =>
    {
        const types = useTypes();
        const rootType =
            useMemo(
                () =>
                    types.Activity.CourseAttendance.Type,
                [types]
            );
        const viewParameters =
            useMemo(
                () =>
                    getViewParameters(rootType),
                [
                    rootType,
                ]
            );
        const view =
            useMemo(
                () =>
                    getSystemDefaultView(
                        rootType,
                        viewParameters,
                        new ComparisonPredicate(
                            new ValueFromEntityComputation(
                                viewParameters.getParameterById(ViewParams.Entity),
                                EntityPath.fromEntityType(rootType)
                                    .joinTo(
                                        types.Activity.Course.RelationshipDefinition.Attendances,
                                        true
                                    )
                                    .field()
                            ),
                            Comparator.Equals,
                            new EntityValue(course)
                        )
                    ),
                [
                    rootType,
                    viewParameters,
                    types,
                    course,
                ]
            );
        const [listSelection, setListSelection] =
            useState<ListSelection>(
                () => ({
                    base: 'none',
                    mutations: [],
                    size: 0
                })
            );
        const originalEmailCommitContext = useNewCommitContext(undefined, { allowAutoCommit: false });
        const originalEmail =
            useMemo(
                () =>
                    constructEntityOfType(
                        types.Activity.Email.Type
                    ),
                [
                    types,
                ]
            );

        return <Card>
            {
                view &&
                <List
                    view={view}
                    selectable
                    selection={listSelection}
                    onSelectionChange={setListSelection}
                    readonly
                />
            }
            <Box
                padding={4}
            >
                <Card>
                    <CardInset>
                        <ActivityEmail
                            entity={originalEmail}
                            commitContext={originalEmailCommitContext}
                            hideToField
                            disableDraftSave
                            disableSend
                        />
                        <PrimaryButton
                            label={
                                <LocalizedText
                                    code="Course.SendEmails"
                                    value="E-mails versturen"
                                />
                            }
                            onClick={
                                async () =>
                                {
                                    const courseAttendanceResults =
                                        await new EntitySelectionBuilder(rootType)
                                            .where(
                                                cb =>
                                                    cb.filter(
                                                        view.filter,
                                                        {
                                                            parameter: viewParameters.getParameterById(ViewParams.Entity),
                                                        }
                                                    )
                                            )
                                            .if(
                                                () =>
                                                    true,
                                                sb =>
                                                    [
                                                        types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                        types.Relationship.RelationshipDefinition.Activities
                                                    ]
                                                    .forEach(
                                                        relationshipDefinition =>
                                                            getEmailAddressesDependencyPathsByType(
                                                                relationshipDefinition.getEntityType(true)
                                                            ).forEach(
                                                                dependencyPath =>
                                                                    sb.join(
                                                                        EntityPath.fromEntityType(rootType)
                                                                            .joinTo(
                                                                                relationshipDefinition,
                                                                                true
                                                                            )
                                                                            .join(dependencyPath)
                                                                    )
                                                            )
                                                    )
                                            )
                                            .select();
                                    const courseAttendances =
                                        courseAttendanceResults.map(
                                            result =>
                                                result.entity
                                        );
                                    const exclusionCourseAttendanceIds = new Set(listSelection.mutations);
                                    const selectedAttendances =
                                        courseAttendances
                                            .filter(
                                                courseAttendance =>
                                                    listSelection.base === 'none'
                                                        ? exclusionCourseAttendanceIds.has(courseAttendance.uuid)
                                                        : !exclusionCourseAttendanceIds.has(courseAttendance.uuid)
                                            );
                                    const commitBuilder = new CommitBuilder();

                                    await new EntityExpansionBuilder(
                                        course.entityType,
                                        [
                                            course
                                        ],
                                        [
                                            EntityPath.fromEntity(course)
                                                .joinTo(
                                                    types.Activity.Course.RelationshipDefinition.Attendances,
                                                    false
                                                )
                                                .joinTo(
                                                    types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                    true
                                                )
                                                .joinTo(
                                                    types.Relationship.RelationshipDefinition.Activities,
                                                    false
                                                )
                                        ]
                                    )
                                        .expand();

                                    for (const selectedAttendance of selectedAttendances)
                                    {
                                        const relationship =
                                            selectedAttendance.getRelatedEntityByDefinition(
                                                true,
                                                types.Relationship.Person.Contact.RelationshipDefinition.Activities
                                            ) ??
                                            selectedAttendance.getRelatedEntityByDefinition(
                                                true,
                                                types.Relationship.RelationshipDefinition.Activities
                                            );
                                        const emailAddress =
                                            getEmailAddressesByEntity(relationship)
                                                .map(
                                                    emailAddress =>
                                                        emailAddress.emailAddress
                                                )
                                                .find(
                                                    () =>
                                                        true
                                                );

                                        if (emailAddress === undefined)
                                        {
                                            alert(
                                                localizeText(
                                                    'Course.MissingEmailAddressForAttendee',
                                                    'Er is geen e-mailadres gevonden voor deelnemer: ${attendee}',
                                                    {
                                                        attendee: relationship?.getName(),
                                                    }
                                                )
                                            );

                                            return;
                                        }

                                        commitBuilder.createEntity(
                                            originalEmail.entityType,
                                            email =>
                                            {
                                                [
                                                    types.Activity.Field.Subject,
                                                    types.Activity.Email.Field.Content,
                                                    types.Activity.Email.Field.HTML,
                                                    types.Activity.Email.Field.TextContent,
                                                ].forEach(
                                                    field =>
                                                        email.setObjectValue(
                                                            field,
                                                            originalEmail.getObjectValueByField(
                                                                field,
                                                                originalEmailCommitContext
                                                            )
                                                        )
                                                );

                                                [
                                                    types.Activity.Email.RelationshipDefinition.Phase,
                                                ].forEach(
                                                    relationshipDefinition =>
                                                        email.relateTo(
                                                            false,
                                                            relationshipDefinition,
                                                            originalEmail.getRelatedEntityByDefinition(
                                                                false,
                                                                relationshipDefinition,
                                                                originalEmailCommitContext
                                                            )
                                                        )
                                                );

                                                email.relateTo(
                                                    true,
                                                    types.Activity.RelationshipDefinition.LinkedActivities,
                                                    selectedAttendance
                                                );

                                                [
                                                    types.Entity.RelationshipDefinition.Attachments,
                                                ].forEach(
                                                    relationshipDefinition =>
                                                        originalEmail.getRelatedEntitiesByDefinition(
                                                            false,
                                                            relationshipDefinition,
                                                            originalEmailCommitContext
                                                        ).forEach(
                                                            attachment =>
                                                                commitBuilder.createEntity(
                                                                    attachment.entityType,
                                                                    builder =>
                                                                        builder
                                                                            .relateTo(
                                                                                true,
                                                                                relationshipDefinition,
                                                                                email.entity
                                                                            )
                                                                            .setObjectValue(
                                                                                types.Attachment.Field.File,
                                                                                attachment.getObjectValueByField(
                                                                                    types.Attachment.Field.File,
                                                                                    originalEmailCommitContext
                                                                                )
                                                                            )
                                                                )
                                                        )
                                                );

                                                email.relateTo(
                                                    true,
                                                    types.Relationship.RelationshipDefinition.Activities,
                                                    selectedAttendance.getRelatedEntityByDefinition(
                                                        true,
                                                        types.Relationship.RelationshipDefinition.Activities
                                                    )
                                                );

                                                email.relateTo(
                                                    true,
                                                    types.Relationship.Person.Contact.RelationshipDefinition.Activities,
                                                    selectedAttendance.getRelatedEntityByDefinition(
                                                        true,
                                                        types.Relationship.Person.Contact.RelationshipDefinition.Activities
                                                    )
                                                );

                                                const from =
                                                    originalEmail.getRelatedEntityByDefinition(
                                                        false,
                                                        types.Activity.Email.RelationshipDefinition.From,
                                                        originalEmailCommitContext
                                                    );

                                                if (from !== undefined)
                                                {
                                                    commitBuilder.createEntity(
                                                        types.Recipient.Email.Type,
                                                        builder =>
                                                            builder
                                                                .relateTo(
                                                                    true,
                                                                    types.Activity.Email.RelationshipDefinition.From,
                                                                    email.entity
                                                                )
                                                                .relateTo(
                                                                    false,
                                                                    types.Recipient.RelationshipDefinition.Addressee,
                                                                    from.getRelatedEntityByDefinition(
                                                                        false,
                                                                        types.Recipient.RelationshipDefinition.Addressee,
                                                                        originalEmailCommitContext
                                                                    )
                                                                )
                                                                .setObjectValue(
                                                                    types.Recipient.Email.Field.EmailAddress,
                                                                    from.getObjectValueByField(
                                                                        types.Recipient.Email.Field.EmailAddress,
                                                                        originalEmailCommitContext
                                                                    )
                                                                )
                                                                .setObjectValue(
                                                                    types.Recipient.Email.Field.Name,
                                                                    from.getObjectValueByField(
                                                                        types.Recipient.Email.Field.Name,
                                                                        originalEmailCommitContext
                                                                    )
                                                                )
                                                    );
                                                }

                                                [
                                                    types.Activity.Email.RelationshipDefinition.CC,
                                                    types.Activity.Email.RelationshipDefinition.BCC,
                                                ].forEach(
                                                    ccOrBcc =>
                                                        originalEmail.getRelatedEntitiesByDefinition(
                                                            false,
                                                            ccOrBcc,
                                                            originalEmailCommitContext
                                                        ).forEach(
                                                            recipient =>
                                                                commitBuilder.createEntity(
                                                                    recipient.entityType,
                                                                    builder =>
                                                                        builder
                                                                            .relateTo(
                                                                                true,
                                                                                ccOrBcc,
                                                                                email.entity
                                                                            )
                                                                            .relateTo(
                                                                                false,
                                                                                types.Recipient.RelationshipDefinition.Addressee,
                                                                                recipient.getRelatedEntityByDefinition(
                                                                                    false,
                                                                                    types.Recipient.RelationshipDefinition.Addressee,
                                                                                    originalEmailCommitContext
                                                                                )
                                                                            )
                                                                            .setObjectValue(
                                                                                types.Recipient.Email.Field.EmailAddress,
                                                                                recipient.getObjectValueByField(
                                                                                    types.Recipient.Email.Field.EmailAddress,
                                                                                    originalEmailCommitContext
                                                                                )
                                                                            )
                                                                            .setObjectValue(
                                                                                types.Recipient.Email.Field.Name,
                                                                                recipient.getObjectValueByField(
                                                                                    types.Recipient.Email.Field.Name,
                                                                                    originalEmailCommitContext
                                                                                )
                                                                            )
                                                                )
                                                        )
                                                );

                                                commitBuilder.createEntity(
                                                    types.Recipient.Email.Type,
                                                    to =>
                                                        to
                                                            .relateTo(
                                                                true,
                                                                types.Activity.Email.RelationshipDefinition.To,
                                                                email.entity
                                                            )
                                                            .relateTo(
                                                                false,
                                                                types.Recipient.RelationshipDefinition.Addressee,
                                                                relationship
                                                            )
                                                            .setObjectValue(
                                                                types.Recipient.Email.Field.EmailAddress,
                                                                emailAddress
                                                            )
                                                );
                                            },
                                            selectedAttendance.uuid
                                        );
                                    }

                                    const result = await commitBuilder.commit();

                                    for (const selectedAttendance of selectedAttendances)
                                    {
                                        const email = result.getEntity(selectedAttendance.uuid);
                                        await sendEmail(email);
                                    }

                                    return onSend();
                                }
                            }
                            disabled={listSelection.size === 0}
                        />
                    </CardInset>
                </Card>
            </Box>
        </Card>;
    };

export default observer(CourseAttendanceEmailing);
