import {Entity} from '../../../../../@Api/Model/Implementation/Entity';
import {useComputed} from 'mobx-react-lite';
import getPhaseRelationshipDefinition
    from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';
import useTypes from "../../Type/Api/useTypes";
import useResult from "../../Selection/Hooks/useResult";
import {useMemo} from "react";

export default function useWorkflowState(entity?: Entity)
{
    const types = useTypes();
    const phaseRelationshipDefinition =
        useMemo(
            () =>
            {
                if (entity)
                {
                    return getPhaseRelationshipDefinition(entity.entityType)
                }
            },
            [
                entity
            ]
        );

    const workflowState =
        useResult(
            types.Datastore.Phase.Type,
            (builder, rootPath) =>
                builder
                    .if(
                        () =>
                            entity !== undefined
                            && phaseRelationshipDefinition !== undefined,
                        sb =>
                            sb.where(
                                cb =>
                                    cb.relatedToEntity(
                                        rootPath
                                            .joinTo(
                                                phaseRelationshipDefinition,
                                                true
                                            ),
                                        entity
                                    )
                            )
                    ),
            [
                types,
                phaseRelationshipDefinition,
                entity
            ]);

    return useComputed(
        () =>
        {
            if (entity && phaseRelationshipDefinition)
            {
                const cachedWorkflowState =  entity.getRelatedEntityByDefinition(
                    false,
                    phaseRelationshipDefinition
                );

                return cachedWorkflowState ?
                    cachedWorkflowState
                    : workflowState
                        && workflowState.length ?
                            workflowState[0]
                            : undefined;
            }
            return undefined;
        },
        [
            entity,
            workflowState,
            phaseRelationshipDefinition
        ]);
}
