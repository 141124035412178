import React, { useContext } from 'react';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import useDialog from '../../../../../@Service/Navigation/Page/Hooks/useDialog';
import { CalendarTimeSlot } from '../../../../../@Future/Component/Generic/Calendar/Calendar';
import useEmployeePlanner from '../../ResourcePlanner/Type/useEmployeePlanner';
import PlanDialog from '../../ResourcePlanner/PlanDialog/PlanDialog';
import CurrentUserContext from '../../../User/CurrentUserContext';
import moment from 'moment';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import localizeText from "../../../../../@Api/Localization/localizeText";

export interface PlanButtonProps
{
    timeSlot?: CalendarTimeSlot;
}

const PlanButton: React.FC<PlanButtonProps> =
    props =>
    {
        const employeePlanner = useEmployeePlanner();
        const currentUserStore = useContext(CurrentUserContext);

        const [ open ] =
            useDialog(
                close =>
                    <PlanDialog
                        onClose={close}
                        resource={currentUserStore.employeeEntity}
                        resourceSelections={employeePlanner.resourceSelections}
                        period={{
                            from: props.timeSlot ? moment(props.timeSlot.start) : moment().startOf('hour').add(1, 'hour'),
                            to: props.timeSlot ? moment(props.timeSlot.end) : moment().startOf('hour').add(2, 'hour')
                        }}
                        parameterAssignment={new ParameterAssignment()}
                    />,
                [
                    currentUserStore.employeeEntity,
                    employeePlanner,
                    props.timeSlot,
                ]);

        return <IconButton
            icon="add"
            tooltip={
                localizeText(
                    'Generic.PlanItem',
                    'Item in agenda inplannen'
                )
            }
            onClick={open}
        />;
    };

export default PlanButton;
