import { Entity } from '../../@Api/Model/Implementation/Entity';
import { identify, Identify, track } from '@amplitude/analytics-browser';
import { loadModuleDirectly } from '../DependencyInjection/index';
import { CurrentUserStore } from '../../@Component/Domain/User/CurrentUserStore';
import { GlobalEnvironment } from '../../@Global/GlobalEnvironment';
import { SettingSource, SettingStore } from '../../@Component/Domain/Setting/SettingStore';
import { Setting } from '../../@Api/Settings/Setting';
import getAmplitudeUserId from './getAmplitudeUserId';
import getIntercomUserId from './getIntercomUserId';
import getTypes from '../../@Component/Domain/Entity/Type/Api/getTypes';

export enum EventTypes {
    AppLaunch = "[Efficy] App Launch",
    Login = "[Efficy] Login",
    LoginError = "[Efficy] Login Error",
    Logout = "[Efficy] Logout",
    LogoutError = "[Efficy] Logout Error",
    ConsultRecord = "[Efficy] Consult Record",
    StartCreateRecord = "[Efficy] Start Create Record",
    FinishCreateRecord = "[Efficy] Finish Create Record",
    AbortCreateRecord = "[Efficy] Abort Create Record",
    NewUIEnabled = "[Efficy] New UI activated",
    NewUIDisabled = "[Efficy] New UI deactivated",
    AddWidget = "[Efficy] Onboarding - Add Widget",
    SendEmail = "[Efficy] Onboarding - First Email Sent",
    SendEmailFailed = "[Efficy] Send mail failed",
    InviteUser = "[Efficy] Onboarding - New User Added",
    ModuleActivated = "[Efficy] Module activated",
    ModuleDeactivated = "[Efficy] Module deactivated",
    AutomationActivated = "[Efficy] Automation activated",
    IntegrationActivated = "[Efficy] Integration activated",
}

export type EventPropertyName =
    '[Efficy] Object Key' |
    '[Efficy] Object Type' |
    '[Efficy] Error' |
    '[Efficy] New License Type' |
    '[Efficy] Previous License Type' |
    '[Efficy] New Number Of Licenses' |
    '[Efficy] Previous Number Of Licenses' |
    '[Efficy] Module Name' |
    '[Efficy] Automation Name' |
    '[Efficy] Integration Name' |
    '[Efficy] Memory usage (Mb)' ;

export interface EventProperty
{
    name: EventPropertyName,
    value: any
}

export default function sendAnalyticsLogging(
    eventType: EventTypes,
    customEventProperties?: EventProperty[],
    entity?: Entity,
    settingStore?: SettingStore
)
{
    try
    {
        if (!GlobalEnvironment.AMPLITUDE_API_KEY || GlobalEnvironment.AMPLITUDE_API_KEY.length ===0)
        {
            return
        }

        const types = getTypes();

        const currentUserStore = loadModuleDirectly(CurrentUserStore);
        const settingStoreStore = settingStore || loadModuleDirectly(SettingStore);

        const v2Enabled = settingStoreStore.getValue(SettingSource.User, Setting.RedirectToV2);

        if (currentUserStore)
        {
            const contract = currentUserStore.contractEntity;
            const isTrial = !contract?.hasValueForField(types.LicenseContract.Field.ActivationDate);

            const identifyEvent = new Identify();
            identifyEvent.set('[Efficy] Customer Name', currentUserStore.currentOrganization?.name);
            identifyEvent.set('[Efficy] Customer Key', currentUserStore.currentOrganization?.id.toString());
            identifyEvent.set('[Efficy] User Key', currentUserStore.employeeEntity.id.toString());
            identifyEvent.set('[Efficy] Account Key', currentUserStore.currentAccount?.id.toString());
            identifyEvent.set('[Efficy] Product Name', 'Tribe CRM');
            identifyEvent.set('[Efficy] New UI', v2Enabled);
            identifyEvent.set('[Efficy] Intercom User ID', getIntercomUserId(currentUserStore));
            identifyEvent.set('[Efficy] Customer type', isTrial ? 'trial' : 'customer');

            if (logOnlyAsIdentify(eventType))
            {
                identifyEvent.setOnce(eventType, true);
            }

            const internalUser = currentUserStore.currentAccount.username.includes('@tribecrm.nl') || currentUserStore.currentAccount.username.includes('@efficy.com');
            identifyEvent.set('[Efficy] Internal User', internalUser);

            identify(identifyEvent);
        }

        if (!logOnlyAsIdentify(eventType))
        {
            const eventProperties = customEventProperties ?? [];

            if (entity != null)
            {
                eventProperties.push(
                    { name: '[Efficy] Object Key', value: entity.id.toString() },
                    { name: '[Efficy] Object Type', value: entity.entityType.getName() }
                );
            }

            const heapSize =
                ((window.performance as any)?.memory?.usedJSHeapSize || 0) / 1024 / 1024;

            if (heapSize > 0)
            {
                eventProperties.push(
                    { name: '[Efficy] Memory usage (Mb)', value: heapSize }
                );
            }

            const eventPropertiesObject = {};
            eventProperties.map(p => eventPropertiesObject[p.name] = p.value);

            const event = {
                event_type: eventType,
                event_properties: eventPropertiesObject,
                user_id: getAmplitudeUserId(currentUserStore)
            }

            track(event);
        }
    }
    catch (e)
    {
        console.error(e.message);
    }
}

function logOnlyAsIdentify(
    eventType: EventTypes
) : boolean
{
    switch (eventType)
    {
        case EventTypes.AddWidget:
        case EventTypes.SendEmail:
        case EventTypes.InviteUser:
            return true;
        default:
            return false;
    }
}