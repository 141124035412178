import { BaseTypeStore } from '../../../@Framework/Store/BaseTypeStore';
import { DataObjectType } from './Model/DataObjectType';
import { DataObject } from './Model/DataObject';
import { DataObjectSpecification } from './Model/DataObjectSpecification';
import { DataDescriptor } from './Model/DataDescriptor';
import { ModuleManager } from '../../../@Util/DependencyInjection/index';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isDate from 'lodash/isDate';
import isBoolean from 'lodash/isBoolean';

export class DataObjectStore extends BaseTypeStore<DataObjectType>
{
    // ------------------------ Dependencies ------------------------

    moduleManager: ModuleManager;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(type: DataObjectType[] = [], moduleManager: ModuleManager)
    {
        super(type);

        this.moduleManager = moduleManager;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    constructSpecification(typeId: string, data: any, required?: boolean): DataObjectSpecification
    {
        let type = this.getTypeById(typeId);

        if (type)
        {
            return new DataObjectSpecification(type, data, required);
        }
        else
        {
            console.error('Cannot construct specification, unknown type: ' + typeId);
            return undefined;
        }
    }

    initializeDataObjects(dataObjects: DataObject[]): Promise<DataObject[]>
    {
        let dataObjectsToInitialize =
            dataObjects
                .filter(dataObject =>
                    (!dataObject.isInitialized
                        && !dataObject.isEmpty
                        && dataObject.specification.type.requiresInitialization(dataObject.value)));

        // Group data objects by type
        let dataObjectsByTypeId = new Map<string, DataObject[]>();
        let typeById = new Map<string, DataObjectType>();

        dataObjectsToInitialize.forEach(
            dataObject =>
            {
                let type = dataObject.specification.type;
                let typeId = type.id();

                if (!dataObjectsByTypeId.has(typeId))
                {
                    dataObjectsByTypeId.set(typeId, []);
                    typeById.set(typeId, type);
                }

                dataObjectsByTypeId.get(typeId).push(dataObject);
            });

        // Initialize each type group
        let promises: Array<Promise<boolean>> = [];

        dataObjectsByTypeId.forEach(
            (dataObjectsForType, typeId) =>
            {
                let type = typeById.get(typeId);

                let promise = type.initialize(dataObjectsForType, this.moduleManager);

                if (promise != null)
                {
                    promises.push(promise);
                }
            });

        return Promise.all(promises)
            .then(results =>
            {
                dataObjectsToInitialize.forEach(
                    dataObject =>
                        dataObject.setInitialized(true));

                return Promise.resolve(dataObjects);
            });
    }

    resolveFromTypeAndValue(typeId: string,
                            value?: any,
                            required?: boolean): DataObject
    {
        let type = this.getTypeById(typeId);

        if (type)
        {
            let dataObject = new DataObject(
                new DataObjectSpecification(
                    type,
                    {},
                    required),
                new DataDescriptor());

            if (value !== undefined)
            {
                dataObject.setValue(value);
            }

            return dataObject;
        }
        else
        {
            return null;
        }
    }

    resolveFromValue(value: any, required?: boolean): DataObject
    {
        if (value)
        {
            let typeId: string;

            if (isNumber(value))
            {
                typeId = 'Number';
            }
            else if (isString(value))
            {
                typeId = 'Text';
            }
            else if (isDate(value))
            {
                typeId = 'DateTime';
            }
            else if (isBoolean(value))
            {
                typeId = 'Boolean';
            }

            if (typeId)
            {
                let type = this.getTypeById(typeId);

                if (type)
                {
                    let dataObject = new DataObject(
                        new DataObjectSpecification(
                            type, {}, required),
                        new DataDescriptor());

                    dataObject.setValue(value);

                    return dataObject;
                }
            }
        }

        return null;
    }

    // ----------------------- Private logic ------------------------
}
