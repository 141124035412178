import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { default as ResourcePlannerModel } from './Model/ResourcePlanner';
import ViewGroup from '../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Resource from './Resource/Resource';
import Period from './Model/Period';
import { groupBy } from '../../../../@Util/MapUtils/groupBy';
import Item from './Model/Item';
import styles from './ResourcePlanner.module.scss';
import getPeriodsInPeriod from './Api/getPeriodsInPeriod';
import CardInset from '../../../../@Future/Component/Generic/Card/CardInset';
import ResourceSelection from './Model/ResourceSelection';
import { StaticOption } from '../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import moment, { Duration } from 'moment';
import { classNames } from '../../../../@Future/Util/Class/classNames';
import useLocalSetting from '../../Setting/Api/useLocalSetting';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { default as TrackModel } from './Resource/Track/Model/Track';
import Cell from './Resource/Track/Model/Cell';
import isPeriodInPeriod from './Api/isPeriodInPeriod';
import isInPeriod from './Api/isInPeriod';
import DragHandle from '../../DragAndDrop/DragHandle/DragHandle';
import Centered from '../../../../@Future/Component/Generic/Centered/Centered';
import Track from './Resource/Track/Track';
import getDurationLabel from '../../../../@Util/Date/getDurationLabel';
import ParameterAssignment from '../../../../@Api/Automation/Parameter/ParameterAssignment';
import ParameterValueEditor from '../Viewer/Content/Automation/Editor/ParameterAssignment/Parameter/ParameterValueEditor';
import { getResourceIntervals, ResourceInterval } from './Model/ResourceInterval';
import useResizeObserver from 'use-resize-observer/polyfilled';
import LocalizedText from '../../Localization/LocalizedText/LocalizedText';
import MenuButton from '../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import DatePicker from '../../../../@Future/Component/Generic/Input/DatePicker/DatePicker';
import useSwitch from '../../../../@Util/Switch/useSwitch';
import useCombinedPaginationResult from '../Selection/Hooks/useCombinedPaginationResult';
import HoverCardMiddle from '../../../../@Future/Component/Generic/Card/HoverCardMiddle/HoverCardMiddle';
import Divider from '../../../../@Future/Component/Generic/Divider/Divider';
import FunctionContext from '../../../../@Api/Automation/Function/FunctionContext';
import HoverCardBottom from '../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import usePaginationResult from '../Selection/Hooks/usePaginationResult';
import { queryOccurrences } from '../../RecurrencePattern/Api/queryOccurrences';
import { useRoutingState } from '../../../../@Service/Router/Model/Api/useRoutingState';
import { useLocalParameterAssignmentSetting } from './Api/useLocalParameterAssignmentSetting';
import { NavigationButtons } from '../../../../@Future/Component/Generic/NavigationButtons/NavigationButtons';
import PrimitiveValue from '../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../DataObject/Model/DataObject';
import { getPeriodSpecification } from './Api/getPeriodSpecification';
import { ResourceSelectionFilter } from './ResourceSelectionFilter/ResourceSelectionFilter';
import CurrentUserContext from "../../User/CurrentUserContext";

export type OnPlan = (resource: Entity, startDate: Date, endDate: Date) => void;
export type TimesheetTotalFormatter = (usedTimeInMillis: number, period: Period, resources: Entity[], intervalType: string, resource?: Entity) => string;

export interface ResourcePlannerProps
{
    id?: string;
    resourcePlanner: ResourcePlannerModel;
    onPlan?: OnPlan;
    name?: React.ReactNode;
    appendix?: React.ReactNode;
    dragHandle?: boolean;
    disableHeader?: boolean;
    period?: Period;
    nestLevel?: number;
    timesheet?: boolean;
    disableTimesheetDialog?: boolean;
    timesheetTotalFormatter?: TimesheetTotalFormatter;
    parameterAssignment?: ParameterAssignment;
    formatResourceName?: (resource: Entity) => React.ReactNode
    intervalType?: string;
    minimumCellDuration?: Duration;
    expandResources?: boolean;
}


const ResourcePlanner: React.FC<ResourcePlannerProps> =
    props =>
    {
        const user = useContext(CurrentUserContext).currentUser;

        const [
            parameterAssignment,
            computedParameterAssignment,
            isLoadingParameterAssignment
        ] = useLocalParameterAssignmentSetting(
            props.resourcePlanner.parameterDictionary,
            props.id === undefined
                ? undefined
                : `User:${user.id}:ResourcePlanner.ParameterAssignment.${props.id}`,
            props.parameterAssignment
        );

        const resourceIntervals: ResourceInterval[] =
            useMemo(
                () =>
                    getResourceIntervals(),
                []
            );

        const intervalOptions =
            useComputed<StaticOption<ResourceInterval>[]>(
                () =>
                    resourceIntervals.slice(
                        resourceIntervals.findIndex(
                            interval =>
                                interval.interval === props.resourcePlanner.minInterval),
                        resourceIntervals.findIndex(
                            interval =>
                                interval.interval === props.resourcePlanner.maxInterval) + 1
                    )
                        .map(
                            resourceInterval => {
                                return {
                                    id: resourceInterval.id,
                                    label: resourceInterval.label,
                                    value: resourceInterval
                                }
                            }),
                [
                    resourceIntervals,
                    props.resourcePlanner
                ]);

        const defaultIntervalId =
            useMemo(
                () =>{
                    const weekInterval = intervalOptions.find(option => option.id === 'week');

                    if (weekInterval)
                    {
                        return weekInterval.id;
                    }
                    else
                    {
                        return intervalOptions[0].id;
                    }
                },
                [
                    intervalOptions
                ]
            );

        const [ intervalId, setIntervalId ] =
            useLocalSetting(
                props.id ? `ResourcePlanner.Interval.${props.id}` : undefined,
                defaultIntervalId
            );

        const interval : ResourceInterval =
            useMemo(
                () =>
                {
                    const option =
                        props.intervalType
                            ? intervalOptions.find(option => option.id === props.intervalType)
                            : intervalOptions.find(option => option.id === intervalId);

                    return option
                        ? option.value
                        : intervalOptions[0].value;
                },
                [
                    intervalId,
                    intervalOptions,
                    props.intervalType
                ]
            )

        const [ dateFromRoutingState, setDateInRoutingState ] =
            useRoutingState(
                props.id === undefined
                    ? undefined
                    :`ResourcePlanner.${props.id}.Date`
            );

        const [ periodStartDate, setPeriodStartDate ] =
            useState(
                () =>
                    moment(dateFromRoutingState).startOf(interval.interval as any)
            );

        const periodEndDate =
            useComputed(
                () =>
                    moment(periodStartDate).startOf(interval.interval as any).add(interval.value, interval.interval as any),
                [
                    periodStartDate
                ]);

        useEffect(
            () =>
                setPeriodStartDate(moment(dateFromRoutingState).startOf(interval.interval as any)),
            [
                interval.interval,
                setPeriodStartDate,
                dateFromRoutingState,
                intervalId
            ]);

        const previous =
            useCallback(
                () =>
                    setDateInRoutingState(moment(periodStartDate).add(-1 * interval.value, interval.interval as any)),
                [
                    interval.interval,
                    interval.value,
                    setDateInRoutingState,
                    periodStartDate
                ]);

        const next =
            useCallback(
                () =>
                    setDateInRoutingState(moment(periodStartDate).add(interval.value, interval.interval as any)),
                [
                    interval.interval,
                    interval.value,
                    setDateInRoutingState,
                    periodStartDate
                ]);

        const period =
            useComputed(
                () =>
                {
                    if (props.period)
                    {
                        return props.period;
                    }
                    else
                    {
                        return {
                            from: periodStartDate,
                            to: periodEndDate
                        };
                    }
                },
                [
                    props.period,
                    periodStartDate,
                    periodEndDate
                ]);

        const {
            pages: resourcesPages,
            loadMore: loadMoreResources,
            hasMore: hasMoreResources,
            isLoading: isLoadingResources
        } = usePaginationResult(
            {
                type: props.resourcePlanner.resourceType,
                isDisabled: isLoadingParameterAssignment,
                callback:
                    (builder) =>
                        builder
                            .if(
                                () =>
                                    props.resourcePlanner.resourceFilter !== undefined,
                                () =>
                                    builder.where(
                                        cb =>
                                            cb.filter(
                                                props.resourcePlanner.resourceFilter,
                                                {
                                                    context:
                                                        new FunctionContext(
                                                            props.resourcePlanner.parameterDictionary
                                                                .getNewDictionaryWithParameter(props.resourcePlanner.startDateParameter)
                                                                .getNewDictionaryWithParameter(props.resourcePlanner.endDateParameter),
                                                            computedParameterAssignment
                                                                .getNewAssignmentWithParameter(
                                                                    props.resourcePlanner.startDateParameter,
                                                                    new PrimitiveValue(
                                                                        DataObject.constructFromTypeIdAndValue(
                                                                            'DateTime',
                                                                            period.from.toDate()
                                                                        )
                                                                    )
                                                                )
                                                                .getNewAssignmentWithParameter(
                                                                    props.resourcePlanner.endDateParameter,
                                                                    new PrimitiveValue(
                                                                        DataObject.constructFromTypeIdAndValue(
                                                                            'DateTime',
                                                                            period.to.toDate()
                                                                        )
                                                                    )
                                                                )
                                                        ),
                                                    parameter: props.resourcePlanner.resourceParameter,
                                                }
                                            )
                                    )
                            )
                            // Add default listDependencies for entitytype
                            .if(
                                () => true,
                                sb =>
                                    props.resourcePlanner.resourceType
                                        .bespoke
                                        .getListDependencies()
                                        .forEach(
                                            dependencyPath =>
                                                sb.join(dependencyPath)
                                        )
                            )
                            .orderBy(
                                props.resourcePlanner.resourceType.bespoke.orderByField(),
                                true
                            )
                },
            [
                props.resourcePlanner,
                computedParameterAssignment,
                isLoadingParameterAssignment,
                period
            ]
        );

        const resources =
            useComputed(
                () =>
                    resourcesPages
                        .map(
                            page =>
                                page.results.map(result => result.entity)
                        )
                        .reduce((a, b) => a.concat(b), []),
                [
                    resourcesPages,
                ]
            );

        const resourceIds =
            useComputed(
                () =>
                    new Set((resources || [])
                        .map(
                            resource =>
                                resource.uuid)),
                [
                    resources
                ]);

        const date =
            useComputed(
                () =>
                {
                    switch (interval.id)
                    {
                        case 'day':
                            return period.from.format('D MMM YYYY');

                        case 'week':
                        case '2week':
                            return `${period.from.format('D MMM')} - ${period.to.format('D MMM YYYY')}`;

                        case 'month':
                            return period.from.format('MMMM YYYY');

                        case 'quarter':
                            return period.from.format('[Q]Q YYYY');

                        case 'year':
                            return period.from.format('YYYY');
                    }
                },
                [
                    interval,
                    period
                ]);


        const [ isDatePickerOpen, openDatePicker, closeDatePicker ] = useSwitch(false);
        const selectDate =
            useCallback(
                (date: Date) =>
                {
                    if (date)
                    {
                        setDateInRoutingState(moment(date).startOf(interval.interval as any));
                        closeDatePicker();
                    }
                },
                [
                    interval.interval,
                    setDateInRoutingState,
                    closeDatePicker
                ]);

        const datePickerExpansion =
            useCallback(
                () =>
                    <CardInset>
                        <DatePicker
                            onChange={selectDate}
                            value={period?.from.toDate()}
                        />
                    </CardInset>,
                [
                    selectDate,
                    period
                ]);

        const datePickerButton =
            !props.period &&
            <MenuButton
                icon="today"
                tooltip={
                    <LocalizedText
                        code="ResourcePlanner.SelectDate"
                        value="Datum opzoeken"
                    />
                }
                expansion={datePickerExpansion}
                onClick={openDatePicker}
                open={isDatePickerOpen}
                onClose={closeDatePicker}
            />;

        const specification =
            useComputed(
                () =>
                {
                    const specification =
                        getPeriodSpecification(period, interval);

                    if (props.minimumCellDuration)
                    {
                        if (specification.headerCellDuration.asHours() < props.minimumCellDuration.asHours())
                        {
                            specification.headerCellDuration = props.minimumCellDuration;
                        }

                        if (specification.secondaryHeaderCellDuration.asHours() < props.minimumCellDuration.asHours())
                        {
                            specification.secondaryHeaderCellDuration = props.minimumCellDuration;
                        }

                        if (specification.dataCellDuration.asHours() < props.minimumCellDuration.asHours())
                        {
                            specification.dataCellDuration = props.minimumCellDuration;
                        }
                    }

                    return specification;
                },
                [
                    period,
                    interval
                ]
            );

        const secondaryHeaderCells =
            useMemo<Period[]>(
                () =>
                    {
                        if (specification.secondaryHeaderCellDuration)
                        {
                            return getPeriodsInPeriod(period, specification.secondaryHeaderCellDuration)
                        }
                        else
                        {
                            return [];
                        }
                    },
                [
                    specification,
                    period
                ]
            );

        const dataCells =
            useMemo<Period[]>(
                () =>
                    getPeriodsInPeriod(
                        period,
                        specification.dataCellDuration),
                [
                    specification,
                    period
                ]);

        const [ selectedResourceSelectionIds, setSelectedResourceSelectionIds ] =
            useLocalSetting<string[]>(`ResourcePlanner.SelectedResourceSelectionIds.${props.id}`);
        const [ selectedResourceSelections, setSelectedResourceSelections ] =
            useState(
                selectedResourceSelectionIds
                    ? props.resourcePlanner.resourceSelections
                        .filter(
                            selection =>
                                selectedResourceSelectionIds
                                    .includes(
                                        selection.parameter.id
                                    )
                        )
                    : props.resourcePlanner.resourceSelections
            );
        const setResourceSelections =
            useCallback(
                (resourceSelections: ResourceSelection[]) =>
                {
                    setSelectedResourceSelections(resourceSelections);
                    setSelectedResourceSelectionIds(
                        props.resourcePlanner.resourceSelections.length === resourceSelections.length
                            ? undefined
                            : resourceSelections
                                .map(
                                    resourceSelection =>
                                        resourceSelection.parameter.id
                                )
                    );
                },
                [
                    props.resourcePlanner.resourceSelections,
                    setSelectedResourceSelections,
                    setSelectedResourceSelectionIds,
                ]
            );
        const resourceSelectionTypes =
            useMemo(
                () =>
                    resources
                        ? selectedResourceSelections
                            .map(
                                selection =>
                                    selection.entityType
                            )
                        : [],
                [
                    resources,
                    selectedResourceSelections
                ]);

        const { results, hasMore, loadMore, isLoading } =
            useCombinedPaginationResult(
                resourceSelectionTypes.map(
                    (resourceSelectionType, idx) => ({
                        type: resourceSelectionType,
                        pageSize: 500,
                        callback:
                            (builder) =>
                            {
                                const selection = selectedResourceSelections[idx];
                                const from = period.from.toDate();
                                const to = period.to.toDate();

                                builder
                                    .join(selection.resourcePath)
                                    .if(
                                        () =>
                                            selection.recurrence !== undefined,
                                        sb =>
                                            sb.where(
                                                cb =>
                                                    cb.or(
                                                        ob =>
                                                            ob
                                                                .overlapsWith(
                                                                    selection.startDateFieldPath,
                                                                    selection.endDateFieldPath,
                                                                    from,
                                                                    to
                                                                )
                                                                .overlapsWithNullableEnd(
                                                                    selection.recurrence.startDateFieldPath,
                                                                    selection.recurrence.endDateFieldPath,
                                                                    from,
                                                                    to
                                                                )
                                                    )
                                            )
                                    )
                                    .if(
                                        () =>
                                            selection.recurrence === undefined,
                                        sb =>
                                            sb.where(
                                                cb =>
                                                    cb.overlapsWith(
                                                        selection.startDateFieldPath,
                                                        selection.endDateFieldPath,
                                                        from,
                                                        to
                                                    )
                                            )
                                    )
                                    .if(
                                        () =>
                                            selection.filter !== undefined,
                                        sb =>
                                            sb.where(
                                                cb =>
                                                    cb.filter(
                                                        selection.filter,
                                                        {
                                                            context:
                                                                new FunctionContext(
                                                                    props.resourcePlanner.parameterDictionary
                                                                        .getNewDictionaryWithParameter(
                                                                            selection.parameter
                                                                        ),
                                                                    computedParameterAssignment
                                                                ),
                                                            parameter: selection.parameter,
                                                        }
                                                    )
                                            )
                                    )
                                    .if(
                                        () =>
                                            selection.highlightFilter !== undefined,
                                        sb =>
                                            selection.highlightFilter.getDependencies()
                                                .filter(
                                                    dependency =>
                                                        dependency.fieldPath?.path.rootEntityType.isA(
                                                            selection.entityType
                                                        )
                                                )
                                                .map(
                                                    dependency =>
                                                        dependency.fieldPath.path
                                                )
                                                .forEach(
                                                    path =>
                                                        sb.join(path)
                                                )
                                    )
                                    .if(
                                        () => true,
                                        sb =>
                                            selection.entityType
                                                .bespoke
                                                .getListDependencies()
                                                .forEach(
                                                    dependencyPath =>
                                                        sb.join(dependencyPath)
                                                )
                                    );
                            }
                    })
                ),
                [
                    selectedResourceSelections,
                    resources,
                    period,
                    computedParameterAssignment
                ]);

        const resultSets =
            useComputed(
                () =>
                    results
                        .map(
                            resultSet =>
                                resultSet.pages()
                                    .map(page => page.results.slice())
                                    .reduce((a, b) => a.concat(b), [])),
                [
                    results
                ]);

        const items =
            useComputed(
                () =>
                    (resultSets || [])
                        .filter(
                            (_, idx) =>
                                selectedResourceSelections[idx])
                        .map(
                            (selectionResult, idx) =>
                                selectionResult
                                    .map(
                                        result =>
                                            selectedResourceSelections[idx].resourcePath.traverseEntity(result.entity)
                                                .map(
                                                    resource => [
                                                        resource,
                                                        result.entity
                                                    ]))
                                    .reduce((a, b) => a.concat(b), [])
                                    .map(
                                        ([ resource, entity ]) =>
                                        {
                                            const selection = selectedResourceSelections[idx];
                                            const itemStartDate: Date = selection.startDateFieldPath.getObjectValue(entity) ?? new Date();
                                            const itemEndDate: Date = selection.endDateFieldPath.getObjectValue(entity) ?? new Date();
                                            const occurrences =
                                                selection.recurrence?.isRecurring(entity)
                                                    ? queryOccurrences({
                                                        pattern: selection.recurrence!.patternFieldPath.getObjectValue(entity),
                                                        from: period.from.toDate(),
                                                        to: period.to.toDate(),
                                                        occurrenceDurationInMillis: itemEndDate.getTime() - itemStartDate.getTime(),
                                                    })
                                                    : [
                                                        {
                                                            idx: 0,
                                                            originalStartDate: itemStartDate,
                                                            startDate: itemStartDate,
                                                            endDate: itemEndDate,
                                                        }
                                                    ];

                                            return occurrences.map(
                                                occurrence =>
                                                    new Item(
                                                        selection,
                                                        entity,
                                                        resource,
                                                        {
                                                            from: moment(occurrence.startDate),
                                                            to: moment(occurrence.endDate),
                                                        }
                                                    )
                                            );
                                        })
                                    .reduce((a, b) => a.concat(b), [])
                        )
                        .reduce((a, b) => a.concat(b), [])
                        .filter(
                            item =>
                                resourceIds.has(item.resource?.uuid)),
                [
                    resultSets,
                    selectedResourceSelections,
                    resourceIds,
                    period,
                ]);

        const itemsByResource =
            useComputed(
                () =>
                    groupBy(
                        items,
                        item =>
                            item.resource),
                [
                    items
                ]);

        const isTimesheet = props.timesheet === undefined ? props.resourcePlanner.isTimesheet : props.timesheet;

        const { ref, width } = useResizeObserver<HTMLDivElement>();
        const isCompact = width < 500;
        const hasScroll = width < 900;
        const dateLabel =
            <span
                className={styles.date}
            >
                {props.name
                    ? props.name
                    : isTimesheet
                        ? <LocalizedText
                            code="Generic.Timesheet"
                            value="Timesheet"
                          />
                        : <LocalizedText
                            code="Generic.Planning"
                            value="Planning"
                          />
                } {date}
            </span>;

        const resourceGroupFilter =
            <ViewGroup
                orientation="horizontal"
                spacing={15}
            >
                {
                    props.resourcePlanner.parameterDictionary.parameters.map(
                        parameter =>
                            <ViewGroupItem
                                key={parameter.id}
                            >
                                <ParameterValueEditor
                                    parameterAssignment={parameterAssignment}
                                    parameter={parameter}
                                    filter
                                />
                            </ViewGroupItem>)
                }
            </ViewGroup>;

        const filterButton =
            <ResourceSelectionFilter
                resourceSelections={props.resourcePlanner.resourceSelections}
                activeSelections={selectedResourceSelections}
                onChange={setResourceSelections}
            />;

        const timelineSize = useResizeObserver<HTMLDivElement>();
        const timelineRef = timelineSize.ref;
        const timelineWidth = timelineSize.width;
        const cells =
            useComputed(
                () =>
                {
                    let primaryHeaderDate = period.from.clone();
                    let secondaryHeaderDate = period.from.clone();
                    let isInActivePeriod = undefined;

                    const getActivePeriod =
                        (period: Period) =>
                        {
                            if (period.from.isoWeekday() >= 6
                                && period.from.isoWeekday() <= 7)
                            {
                                return {
                                    from: moment(period.from).endOf('day'),
                                    to: moment(period.from).endOf('day')
                                };
                            }
                            else
                            {
                                if (specification.dataCellDuration.asHours() < 1)
                                {
                                    return {
                                        from: moment(period.from).startOf('day').set('hour', 6),
                                        to: moment(period.from).startOf('day').set('hour', 18)
                                    };
                                }
                                else
                                {
                                    return period;
                                }
                            }
                        };

                    return getPeriodsInPeriod(
                        period,
                        specification.dataCellDuration
                    )
                        .map(
                            (period, idx) =>
                            {
                                const currentActivePeriod = getActivePeriod(period);
                                const isActive = isPeriodInPeriod(period, currentActivePeriod, true);

                                const wasInActivePeriod = isInActivePeriod;
                                isInActivePeriod = isActive;

                                const isPrimaryHeader = isInPeriod(primaryHeaderDate, period);
                                const isSecondaryHeader = isInPeriod(secondaryHeaderDate, period);

                                const cell =
                                    new Cell(
                                        idx,
                                        period,
                                        isActive,
                                        isPrimaryHeader,
                                        isSecondaryHeader
                                        &&
                                        (specification.dataCellDuration.asHours() >= 6
                                            ||
                                            (isActive || wasInActivePeriod !== isInActivePeriod)
                                        )
                                    );

                                if (isPrimaryHeader)
                                {
                                        primaryHeaderDate.add(specification.headerCellDuration);
                                }
                                if (isSecondaryHeader)
                                {
                                    secondaryHeaderDate.add(specification.secondaryHeaderCellDuration);
                                }

                                return cell;
                            });
                },
                [
                    period,
                    specification
                ]);

        const track =
            useComputed(
                () =>
                    timelineWidth &&
                    TrackModel.buildTrack(
                        period,
                        timelineWidth,
                        cells,
                        specification.activeToInactivePeriodRatio),
                [
                    period,
                    timelineWidth,
                    cells,
                    specification
                ]);

        const header =
            <CardInset
                vertical={false}
            >
                <ViewGroup
                    orientation="vertical"
                    spacing={5}
                >
                    {
                        isCompact &&
                        <ViewGroupItem>
                            <ViewGroup
                                orientation="horizontal"
                                spacing={15}
                                alignment="center"
                            >
                                <ViewGroupItem>
                                    {dateLabel}
                                </ViewGroupItem>
                                <ViewGroupItem
                                    ratio={1}
                                />
                                <ViewGroupItem>
                                    {filterButton}
                                </ViewGroupItem>
                                <ViewGroupItem>
                                    {props.appendix}
                                </ViewGroupItem>
                            </ViewGroup>
                        </ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={15}
                            alignment="center"
                        >
                            <ViewGroupItem>
                                <NavigationButtons
                                    onBackClicked={previous}
                                    onForwardClicked={next}
                                />
                            </ViewGroupItem>
                            {
                                !isCompact &&
                                <ViewGroupItem ratio={1}>
                                    {dateLabel}
                                </ViewGroupItem>
                            }
                            <ViewGroupItem
                                ratio={isCompact ? 1 : undefined}
                            >
                                <div
                                    className={styles.buttonGroup}
                                >
                                    {
                                        intervalOptions.map(
                                            option =>
                                                <div
                                                    key={option.id}
                                                    className={classNames(styles.button, intervalId === option.id && styles.selected)}
                                                    onClick={() => setIntervalId(option.id)}
                                                >
                                                    {option.label}
                                                </div>)
                                    }
                                </div>
                            </ViewGroupItem>
                            {
                                !isCompact &&
                                <ViewGroupItem>
                                    {datePickerButton}
                                </ViewGroupItem>
                            }
                            {
                                !isCompact &&
                                <ViewGroupItem>
                                    {filterButton}
                                </ViewGroupItem>
                            }
                            {
                                !isCompact &&
                                <ViewGroupItem>
                                    {props.appendix}
                                </ViewGroupItem>
                            }
                        </ViewGroup>
                    </ViewGroupItem>
                    <ViewGroupItem>
                        <ViewGroup
                            orientation="horizontal"
                            spacing={0}
                            alignment="center"
                            justification="end"
                        >
                            <ViewGroupItem>
                                {resourceGroupFilter}
                            </ViewGroupItem>
                        </ViewGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </CardInset>;

        const allTrackItems =
            useComputed(
                () =>
                    track
                        ?
                        items
                            .map(
                                item =>
                                    track.getTrackItem(item))
                        :
                        [],
                [
                    items,
                    track
                ]);

        const totalDuration =
            useComputed(
                () =>
                {
                    let duration = 0;

                    allTrackItems
                        .forEach(
                            item =>
                            {
                                duration += item.item.period.to.diff(item.item.period.from, 'milliseconds');
                            });

                    return duration;
                },
                [
                    allTrackItems,
                    track
                ]);

        return <div
            ref={ref}
            className={classNames(styles.root, hasScroll && styles.scroll)}
        >
            <ViewGroup
                orientation="vertical"
                spacing={5}
            >
                {
                    !props.disableHeader &&
                    <ViewGroupItem>
                        {
                            props.dragHandle
                                ?
                                    <DragHandle>
                                        {header}
                                    </DragHandle>
                                :
                                    header
                        }
                    </ViewGroupItem>
                }
                {
                    hasMore &&
                    <ViewGroupItem>
                        <Divider />
                        <HoverCardMiddle
                            onClick={loadMore}
                            disabled={isLoading}
                        >
                            <LocalizedText
                                code="Generic.NotAllResultsLoaded"
                                value="Niet alle resultaten zijn ingeladen. Klik hier om meer resultaten in te laden."
                            />
                        </HoverCardMiddle>
                        <Divider />
                    </ViewGroupItem>
                }
                <ViewGroupItem
                    className={styles.planner}
                >
                    <Table
                        className={styles.table}
                    >
                        <TableHead
                            className={classNames(styles.header, props.disableHeader && styles.disabled)}
                        >
                            <TableRow>
                                <TableCell
                                    className={styles.resourceHeaderCell}
                                >
                                    {!props.disableHeader && props.resourcePlanner.resourceType.getName(true)}
                                </TableCell>
                                <TableCell
                                    className={styles.headerTrackCell}
                                    padding="none"
                                    ref={timelineRef}
                                />
                                <TableCell
                                    className={styles.totalHeaderCell}
                                    align="right"
                                >
                                    {
                                        !props.disableHeader &&
                                        <div
                                            className={styles.label}
                                        >
                                            <LocalizedText
                                                code="Generic.Total"
                                                value="Totaal"
                                            />
                                        </div>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            resources && track &&
                            <TableBody>
                                {
                                    resources.map(
                                        (resource, idx) =>
                                            <Resource
                                                key={resource.uuid}
                                                resourcePlanner={props.resourcePlanner}
                                                period={period}
                                                parameterAssignment={parameterAssignment}
                                                specification={specification}
                                                resources={resources}
                                                resource={resource}
                                                headerCells={secondaryHeaderCells}
                                                dataCells={dataCells}
                                                track={track}
                                                items={itemsByResource.get(resource) || []}
                                                first={idx === 0}
                                                last={idx === resources.length - 1}
                                                onPlan={props.onPlan}
                                                disableHeader={props.disableHeader}
                                                nestLevel={props.nestLevel}
                                                timesheet={isTimesheet}
                                                disableTimesheetDialog={props.disableTimesheetDialog}
                                                timesheetTotalFormatter={props.timesheetTotalFormatter}
                                                intervalType={intervalId}
                                                formatResourceName={props.formatResourceName}
                                                minimumCellDuration={props.minimumCellDuration}
                                                expanded={props.expandResources}
                                            />
                                    )
                                }
                                {
                                    hasMoreResources &&
                                    <TableRow>
                                        <TableCell
                                            padding="none"
                                        >
                                            <HoverCardBottom
                                                onClick={loadMoreResources}
                                                disabled={isLoadingResources}
                                            >
                                                <LocalizedText
                                                    code="Generic.LoadMore"
                                                    value="Meer laden"
                                                />...
                                            </HoverCardBottom>
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    isTimesheet && props.nestLevel === 0 &&
                                    <TableRow
                                        className={styles.totalRow}
                                    >
                                        <TableCell
                                            padding="none"
                                            className={styles.totalLabelCell}
                                            align="right"
                                        >
                                            <LocalizedText
                                                code="Generic.Total"
                                                value="Totaal"
                                            />
                                        </TableCell>
                                        <TableCell
                                            padding="none"
                                            className={styles.totalTrackCell}
                                        >
                                            <Track
                                                resourcePlanner={props.resourcePlanner}
                                                period={period}
                                                parameterAssignment={computedParameterAssignment}
                                                specification={specification}
                                                resources={resources}
                                                resource={undefined}
                                                headerCells={secondaryHeaderCells}
                                                dataCells={dataCells}
                                                track={track}
                                                trackItems={allTrackItems}
                                                items={items}
                                                last
                                                onPlan={props.onPlan}
                                                disableHeader={props.disableHeader}
                                                nestLevel={props.nestLevel}
                                                timesheet
                                                disableTimesheetDialog={props.disableTimesheetDialog}
                                                timesheetTotalFormatter={props.timesheetTotalFormatter}
                                                intervalType={intervalId}
                                            />
                                        </TableCell>
                                        <TableCell
                                            padding="none"
                                            align="right"
                                            className={styles.totalCell}
                                        >
                                            {props.timesheetTotalFormatter(totalDuration, period, resources, intervalId)}
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        }
                        {
                            resources && resources.length === 0 &&
                            <TableBody>
                                <TableCell
                                    colSpan={3}
                                >
                                    <Centered
                                        horizontal
                                    >

                                        <LocalizedText
                                            code="ResourcePlanner.ResourceNotFound"
                                            value="Er zijn geen ${resourceType} gevonden."
                                            resourceType={props.resourcePlanner.resourceType.getName(true).toLowerCase()}
                                        />
                                    </Centered>
                                </TableCell>
                            </TableBody>
                        }
                    </Table>
                </ViewGroupItem>
            </ViewGroup>
        </div>;
    };

ResourcePlanner.defaultProps = {
    nestLevel: 0,
    timesheetTotalFormatter:
        timeInMillis => getDurationLabel(timeInMillis)
};

export default observer(ResourcePlanner);
