import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import useWorkflowState from '../../Workflow/Api/useWorkflowState';
import useWorkflowStateIsTerminal from '../../Workflow/Api/useWorkflowStateIsTerminal';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { colorToCss } from '../../../DataObject/Type/Color/ColorType';
import Popper from '../../../../../@Future/Component/Generic/Popper/Popper';
import Card from '../../../../../@Future/Component/Generic/Card/Card';
import Menu from '../../../../../@Future/Component/Generic/Menu/Menu';
import useWorkflowStates from '../../Workflow/Api/useWorkflowStates';
import Item from '../../../../../@Future/Component/Generic/Menu/Item/Item';
import useIsDisabled from '../../Workflow/Api/useIsDisabled';
import useTypes from '../../Type/Api/useTypes';
import getPhaseRelationshipDefinition from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/getPhaseRelationshipDefinition';
import { EntityType } from '../../../../../@Api/Model/Implementation/EntityType';
import { EntityExpansionBuilder } from '../../Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../Path/@Model/EntityPath';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import usePipeline from '../../../../../@Api/Entity/Bespoke/Datastore/Phase/usePipeline';
import { CommitBuilder } from '../../../../../@Api/Entity/Commit/Context/Builder/CommitBuilder';

export interface CloseStateButtonProps
{
    entity: Entity;
    entityType?: EntityType;
    disabled?: boolean;
}

const CloseStateButton: React.FC<CloseStateButtonProps> =
    props =>
    {
        const types = useTypes();
        const isDisabled = useIsDisabled(props.entityType || props.entity.entityType) || props.disabled;
        const workflowState = useWorkflowState(props.entity);
        const isClosed = useWorkflowStateIsTerminal(workflowState);
        const [ isHovering, setHovering ] = useState(false);
        const pipeline = usePipeline(props.entity);
        const closedWorkflowStates = useWorkflowStates(props.entityType || props.entity.entityType, true, pipeline);

        const startHovering =
            useCallback(
                () =>
                {
                    if (!isDisabled)
                    {
                        setHovering(true);
                    }
                },
                [
                    setHovering,
                    isDisabled
                ]);
        const stopHovering =
            useCallback(
                () =>
                {
                    if (!isDisabled)
                    {
                        setHovering(false);
                    }
                },
                [
                    setHovering,
                    isDisabled
                ]);

        const [ isMenuOpen, setMenuOpen ] = useState(false);
        const setState =
            useCallback(
                (state: Entity) =>
                {
                    if (props.entity && state)
                    {
                        return new CommitBuilder()
                            .relateEntityTo(
                                props.entity,
                                false,
                                getPhaseRelationshipDefinition(props.entity.entityType),
                                state
                            )
                            .commit()
                            .then(startHovering)
                    }
                },
                [
                    props.entity
                ]);

        const onClick =
            useCallback(
                async () =>
                {
                    const rootPath = EntityPath.fromEntity(props.entity);
                    const pathToPhase =
                        rootPath.joinTo(
                            getPhaseRelationshipDefinition(props.entity.entityType),
                            false);
                    const currentPhase = pathToPhase.traverseEntity(props.entity).find(() => true);

                    if (!currentPhase)
                    {
                        await new EntityExpansionBuilder(
                            props.entity.entityType,
                            [
                                props.entity
                            ],
                            [
                                pathToPhase
                            ])
                            .expand();
                    }

                    if (isClosed)
                    {
                        const pathToLastOpenPhase =
                            rootPath
                                .joinTo(
                                    types.Entity.RelationshipDefinition.ClosePhase,
                                    false);

                        await new EntityExpansionBuilder(
                            props.entity.entityType,
                            [
                                props.entity
                            ],
                            [
                                pathToLastOpenPhase
                            ])
                            .expand();

                        return setState(pathToLastOpenPhase.traverseEntity(props.entity).find(() => true));
                    }
                    else
                    {
                        if (!closedWorkflowStates || closedWorkflowStates.length === 0)
                        {
                            return;
                        }
                        else if (closedWorkflowStates.length === 1)
                        {
                            setMenuOpen(false);

                            return setState(closedWorkflowStates[0]);
                        }
                        else
                        {
                            return setMenuOpen(true);
                        }
                    }
                },
                [
                    props.entity,
                    types,
                    isClosed,
                    setMenuOpen,
                    setState,
                    closedWorkflowStates
                ]);

        useEffect(
            () =>
                stopHovering(),
            [
                isClosed
            ]
        );

        const color =
            useMemo(
                () =>
                {
                    if (isHovering)
                    {
                        if (isClosed)
                        {
                            return 'rgb(219, 219, 219)';
                        }
                        else
                        {
                            return 'rgb(126, 211, 33)';
                        }
                    }
                    else
                    {
                        if (isClosed)
                        {
                            return 'rgb(126, 211, 33)';
                        }
                        else
                        {
                            return 'rgb(219, 219, 219)';
                        }
                    }
                },
                [
                    isClosed,
                    isHovering,
                ]);

        const closeMenu =
            useCallback(
                () =>
                    setMenuOpen(false),
                [
                    setMenuOpen
                ]);

        return <div
            onMouseEnter={startHovering}
            onMouseLeave={stopHovering}
            onClick={e => e.stopPropagation()}
        >
            <Popper
                reference={
                    <>
                        <IconButton
                            icon={isClosed ? workflowState.getObjectValueByField(types.Datastore.Phase.Field.Icon) : 'check'}
                            tooltip={
                                isDisabled
                                    ?
                                        ''
                                    :
                                        isClosed
                                            ?
                                                <LocalizedText
                                                    code="Generic.Reopen"
                                                    value="Heropenen"
                                                />
                                            :
                                                <LocalizedText
                                                    code="Generic.Finish"
                                                    value="Voltooien"
                                                />
                            }
                            onClick={onClick}
                            color={color}
                            filled={isHovering ? !isClosed : isClosed}
                            outlined={isHovering ? isClosed : !isClosed}
                            borderSize={1}
                            size={20}
                            disabled={isDisabled}
                        />
                    </>
                }
                popper={
                    <Card>
                        <Menu>
                            {
                                closedWorkflowStates &&
                                    closedWorkflowStates.map(
                                        state =>
                                            <Item
                                                key={state.uuid}
                                                icon={state.getObjectValueByField(types.Datastore.Phase.Field.Icon)}
                                                iconColor={colorToCss(state.getObjectValueByField(types.Datastore.Phase.Field.Color))}
                                                name={state.name}
                                                onClick={() => setState(state)}
                                            />)
                            }
                        </Menu>
                    </Card>
                }
                open={isMenuOpen}
                onClose={closeMenu}
            />

        </div>;
    };

export default CloseStateButton;
