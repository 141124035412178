import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {observer, useComputed} from 'mobx-react-lite';
import {Entity} from '../../../../../../../../../../@Api/Model/Implementation/Entity';
import Card from '../../../../../../../../../../@Future/Component/Generic/Card/Card';
import styles from './Item.module.scss';
import {EntityByDate} from '../../../../../../../../../../@Api/Model/Implementation/EntityByDate';
import {openEntity} from '../../../../../../../@Util/openEntity';
import Inset from '../../../../../../../Item/Content/Inset/Inset';
import Region from '../../../../../../../Item/Content/Click/Region/Region';
import useTypes from '../../../../../../../Type/Api/useTypes';
import Divider from '../../../../../../../../../../@Future/Component/Generic/Divider/Divider';
import More from '../../../../../../../Item/Navigator/More/More';
import List from '../../../../../../../Item/Navigator/List/List';
import useNotePath from '../../../../../../../../../../@Api/Entity/Bespoke/useNotePath';
import EntityExpansionContext, {ExpansionType} from '../../../Context/EntityExpansionContext';
import equalsEntity from '../../../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import useOpenEntityCallback from '../../../../../../Context/OpenedEntity/useOpenEntityCallback';
import useIsMobile from '../../../../../../../../../../@Util/Responsiveness/useIsMobile';
import {classNames} from '../../../../../../../../../../@Future/Util/Class/classNames';
import useAttachmentPath from '../../../../../../../../../../@Api/Entity/Bespoke/useAttachmentPath';
import hasMore from '../../../Api/hasMore';
import Icon from './Icon';
import ViewGroup from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardHeader from '../../../../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader';
import Options from './Options/Options';
import {useNewCommitContext} from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/useNewCommitContext';
import {useCommittableEntity} from '../../../../../../../../../../@Api/Entity/Commit/Context/Api/useCommittableEntity';
import OnFileDropDisableContext
    from '../../../../../../../../../../@Future/Component/Generic/FileDrop/OnFileDropDisableContext';
import {Content} from './Content/Content';

export interface ItemProps
{
    entityByDate: EntityByDate;
    rootEntity: Entity;
    notesOpenByDefault: boolean;
    first?: boolean;
    last?: boolean;
    closeable?: boolean;
    highlighted?: boolean;
    open?: boolean;
}

const Item: React.FC<ItemProps> =
    props =>
    {
        const types = useTypes();
        const commitContext = useNewCommitContext();
        const entity =
            useCommittableEntity(
                props.entityByDate.entity,
                commitContext
            );

        const [ expansions, setExpansions ] = useContext(EntityExpansionContext);
        const [ syncWithNotesOpenByDefault, setSyncNotesOpenByDefault ] = useState(props.notesOpenByDefault);
        const [ showNotesInternal, setShowNotesInternal ] = useState(props.notesOpenByDefault);
        const [ isLayoutInEditMode, setLayoutEditMode ] = useState(false);

        const _hasMore =
            useComputed(
                () =>
                    hasMore(entity),
                [
                    entity
                ]);

        const isOpenable =
            useComputed(
                () =>
                    !_hasMore
                    && !entity.entityType.isA(types.Activity.Task.Type)
                    && !entity.entityType.isA(types.Activity.Appointment.Type)
                    && !entity.entityType.isA(types.Activity.SpotlerCampaignResult.Type)
                    && !entity.entityType.isA(types.Note.Type)
                    && !entity.entityType.isA(types.TimeRegistration.Type)
                    && !entity.entityType.isA(types.Activity.ApsisCampaignResult.Type)
                    && !entity.entityType.isA(types.Activity.ApsisFormResult.Type),
                [
                    _hasMore,
                    entity,
                    types
                ]);

        const isClickable =
            useMemo(
                () =>
                    !props.closeable && (isOpenable || _hasMore) && !isLayoutInEditMode,
                [
                    _hasMore,
                    isOpenable,
                    props.closeable,
                    isLayoutInEditMode
                ]);

        const pathToNotes = useNotePath(entity);
        const pathToAttachments = useAttachmentPath(entity);
        const hasNotes =
            useComputed(
                () =>
                    entity.entityType.isA(types.Activity.Type)
                    || entity.entityType.isA(types.Attachment.Type),
                [
                    entity,
                    types
                ])

        const hasAttachments =
            useComputed(
                () =>
                    hasNotes && !entity.entityType.isA(types.Attachment.Type),
                [
                    hasNotes,
                    entity,
                    types
                ]);

        const setOpenedEntity = useOpenEntityCallback();

        const showMore =
            useMemo(
                () =>
                    expansions
                    && equalsEntity(expansions.entity, entity)
                    && expansions.types.has('More')
                    && _hasMore,
                [
                    expansions,
                    entity,
                    _hasMore
                ]);

        const addExpansion = (entity: Entity, type: ExpansionType) => {
            const newExpansions = {
                    entity: entity,
                    types: expansions ? expansions.types.add(type) : new Set([type])
                };

            // Notes and Attachments are mutually exclusive
            if (expansions)
            {
                if (type === 'Attachments')
                {
                    newExpansions.types.delete('Notes')
                }
                else if (type === 'Notes')
                {
                    newExpansions.types.delete('Attachments')
                }
            }
            setExpansions(newExpansions);
        }

        const removeExpansion = (type: ExpansionType) => {
            if(!expansions) return;

            let newExpansions = expansions;
            newExpansions.types.delete(type);
            if (newExpansions.types.size === 0)
            {
                setExpansions(undefined);
            }
            else
            {
                setExpansions(newExpansions);
            }
        }


        const toggleMore =
            useCallback(
                () =>
                {
                    if (showMore)
                    {
                        setOpenedEntity(undefined);
                        removeExpansion('More')
                    }
                    else
                    {
                        addExpansion(entity, 'More');
                    }
                },
                [
                    showMore,
                    setOpenedEntity,
                    setExpansions,
                    expansions,
                    entity
                ]);

        const showAttachments =
            useMemo(
                () =>
                    expansions
                    && equalsEntity(expansions.entity, entity)
                    && expansions.types.has('Attachments'),
                [
                    expansions,
                    entity
                ]);

        const showNotes =
            useMemo(
                () =>
                    (
                        (
                            showNotesInternal
                            ||
                            (   hasNotes
                                && syncWithNotesOpenByDefault
                            )
                        )
                        && !showAttachments
                    ),
                [
                    hasNotes,
                    showNotesInternal,
                    syncWithNotesOpenByDefault,
                    showAttachments
                ]);

        const toggleNotes =
            useCallback(
                () =>
                {
                    setSyncNotesOpenByDefault(false)
                    if (showNotes)
                    {
                        setOpenedEntity(undefined);
                        removeExpansion('Notes')
                    }
                    else
                    {
                        addExpansion(entity, 'Notes');
                    }
                    setShowNotesInternal(!showNotesInternal)
                },
                [
                    setSyncNotesOpenByDefault,
                    setShowNotesInternal,
                    showNotesInternal,
                    showNotes,
                    setOpenedEntity,
                    removeExpansion,
                    addExpansion,
                    expansions,
                    entity
                ]);

        const disableFileDrop = useContext(OnFileDropDisableContext);

        const toggleAttachments =
            useCallback(
                () =>
                {
                    if (showAttachments)
                    {
                        setOpenedEntity(undefined);
                        setShowNotesInternal(false);
                        removeExpansion('Attachments')
                        if (disableFileDrop)
                        {
                            disableFileDrop(false);
                        }
                    }
                    else
                    {
                        addExpansion(entity, 'Attachments');
                        if (disableFileDrop)
                        {
                            disableFileDrop(true);
                        }
                    }
                },
                [
                    showAttachments,
                    setOpenedEntity,
                    setShowNotesInternal,
                    setExpansions,
                    expansions,
                    entity,
                    disableFileDrop
                ]);

        const onClick =
            useCallback(
                () =>
                {
                    if (isOpenable)
                    {
                        openEntity(entity)
                             .then();
                    }
                    else if (_hasMore)
                    {
                        toggleMore();
                    }
                },
                [
                    isOpenable,
                    _hasMore,
                    entity,
                    toggleMore
                ]);

        useEffect(
            () =>
            {
                setSyncNotesOpenByDefault(props.notesOpenByDefault)
                setOpenedEntity(undefined);
                removeExpansion('Notes')
            },
            [
                props.notesOpenByDefault
            ]
        );


        const isLastItem =
            useMemo(
                () =>
                    props.last,
                [
                    props.last
                ]);

        const isCompact = useIsMobile();
        const cardClasses =
            useMemo(
                () => ({
                    root: entity.entityType.isA(types.Note.Type) && styles.noteCard
                }),
                [
                    entity,
                    types
                ]);

        return <div
            className={classNames(styles.root, isCompact && styles.compact, isClickable && styles.clickable, showMore && styles.highlighted)}
        >
            {
                !showMore && isLastItem &&
                    <div
                        className={styles.invertedLineOnLastItem}
                    />
            }
            {
                !showMore && !isCompact &&
                    <Icon
                        icon={entity.entityType.getInheritedIcon()}
                    />
            }
            <div
                className={styles.data}
            >
                <Card
                    classes={cardClasses}
                >
                    <div
                        className={classNames(styles.header, showMore && styles.highlighted)}
                    >
                        <Region
                            clickable={isClickable}
                            onClick={onClick}
                        >
                            <Inset
                                horizontal
                                vertical
                            >
                                {
                                    props.highlighted
                                        ?
                                            <ViewGroup
                                                orientation="horizontal"
                                                spacing={15}
                                                alignment="center"
                                            >
                                                <ViewGroupItem
                                                    ratio={1}
                                                >
                                                    <CardHeader>
                                                        {(entity.entityType.getSwitchableType() || entity.entityType).getName()}
                                                    </CardHeader>
                                                </ViewGroupItem>
                                                <ViewGroupItem>
                                                    <Options
                                                        entity={entity}
                                                        commitContext={commitContext}
                                                        compact={false}
                                                        labelPosition="left"
                                                        inlineLabel
                                                        hasNotes={ hasNotes}
                                                        hasAttachments={ hasAttachments}
                                                        showNotes={showNotes}
                                                        showAttachments={showAttachments}
                                                        toggleNotes={toggleNotes}
                                                        toggleAttachments={toggleAttachments}
                                                        closeable={props.closeable}
                                                    />
                                                </ViewGroupItem>
                                            </ViewGroup>
                                        :
                                            <Content
                                                entity={entity}
                                                commitContext={commitContext}
                                                compact={false}
                                                labelPosition="left"
                                                inlineLabel
                                                // inlineLabel={!isCompact}
                                                hasNotes={hasNotes}
                                                hasAttachments={hasAttachments}
                                                showNotes={showNotes}
                                                showAttachments={showAttachments}
                                                toggleNotes={toggleNotes}
                                                toggleAttachments={toggleAttachments}
                                                closeable={props.closeable}
                                                isLayoutInEditMode={isLayoutInEditMode}
                                                onChangeLayoutEditMode={setLayoutEditMode}
                                            />
                                }
                            </Inset>
                        </Region>
                    </div>
                    {
                        showMore && props.open &&
                            <>
                                <Divider />
                                <More
                                    entity={entity}
                                />
                            </>
                    }
                    {
                        (showNotes || showAttachments) &&
                            <>
                                <Divider />
                                <Inset
                                    horizontal
                                    vertical
                                >
                                    <List
                                        entity={entity}
                                        path={showNotes ? pathToNotes : pathToAttachments}
                                    />
                                </Inset>
                            </>
                    }
                </Card>
            </div>
        </div>;
    };

export default observer(Item);
