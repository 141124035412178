import React, {useCallback, useMemo, useState} from 'react';
import { observer } from 'mobx-react-lite';
import { CronEditorProps } from '../CronEditor';
import NumberEditor from '../../NumberEditor/NumberEditor';
import ViewGroup from '../../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../../ViewGroup/ViewGroupItem';
import localizeText from '../../../../../../@Api/Localization/localizeText';
import {loadModuleDirectly} from '../../../../../../@Util/DependencyInjection';
import {FeedbackStore} from '../../../../../../@Component/App/Root/Environment/Organization/Feedback/FeedbackStore';

export interface MinutelyCronEditorProps extends CronEditorProps
{

}

const MinimumMinutes: number = 15

const MinutelyCronEditor: React.FC<MinutelyCronEditorProps> =
    props =>
    {
        const [ minutes, setMinutes ] = useState<number>(
            parseInt(
                props.value?.split(' ')[1].split('/')[1]
            )
            || MinimumMinutes
        )

        const snackBar =
            useMemo(
                () =>
                    loadModuleDirectly(FeedbackStore),
                []
            );

        const { onChange } = props;
        const onChangeMinutes =
            useCallback(
                (minutes: number) =>
                    setMinutes(minutes),
                [
                    setMinutes
                ]);

        const onBlur =
            useCallback(
                () =>
                {
                    let validMinutes = minutes
                    if(!validMinutes || validMinutes < MinimumMinutes)
                    {
                        validMinutes = MinimumMinutes
                        snackBar.enqueueSnackbar(
                            localizeText(
                                'InvalidMinuteValue',
                                "Het interval van de automation kan niet kleiner zijn dan 15 minuten",
                            ),
                            {
                                variant: 'error'
                            }
                        )
                    }
                    const split = props.value.split(' ');
                    split.splice(
                        1,
                        1,
                        `0/${validMinutes}`);
                    setMinutes(validMinutes);
                    onChange(split.join(' '));
                },
                [
                    minutes,
                    props.value,
                    onChange,
                    snackBar,
                    MinimumMinutes
                ]
            )

        return <ViewGroup
            orientation="horizontal"
            spacing={5}
            alignment="center"
        >
            <ViewGroupItem>
                Om de
            </ViewGroupItem>
            <ViewGroupItem>
                <NumberEditor
                    value={minutes}
                    onChange={onChangeMinutes}
                    onBlur={onBlur}
                    fitContent
                />
            </ViewGroupItem>
            <ViewGroupItem>
                {localizeText('Generic.Minutes', 'minuten')}
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(MinutelyCronEditor);